<template>
    <label
        ref="label"
        :for="id"
        :style="{ height: height }"
        class="form-checkbox-button"
        :class="{ selected: isSelected, disabled: disabled }"
        :disabled="disabled"
        :tabindex="disabled ? false : 0"
        @keydown.prevent.enter.space="$refs.label.click"
    >
        <div class="flex justify-between items-center w-full">
            <div class="flex-1 flex items-center">
                <input
                    v-model="newValue"
                    :value="nativeValue"
                    :name="name"
                    :disabled="disabled"
                    type="checkbox"
                    class="hidden"
                    @change="$emit('change')"
                >
                <slot />
            </div>

            <div
                v-if="showCheck"
                class="flex justify-center items-center mr-3 w-10 h-10 border rounded-lg"
                :class="[isSelected ? 'bg-brand-500 border-brand-500' : 'bg-white border-gray-300']"
            >
                <svg
                    v-if="isSelected"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>

                <svg
                    v-if="disabled"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                </svg>
            </div>
        </div>
    </label>
</template>

<script>
export default {
    name: 'FormCheckboxButton',

    props: {
        value: [String, Number, Boolean, Function, Object, Array, Symbol],
        nativeValue: [String, Number, Boolean, Function, Object, Array, Symbol],
        id: String,
        name: String,
        disabled: Boolean,
        showCheck: Boolean,

        height: {
            type: String,
            default: 'auto',
        },
    },

    data() {
        return {
            newValue: this.value,
        };
    },

    watch: {
        value(value) {
            this.newValue = value;
        },

        newValue(value) {
            this.$emit('input', value);
        },
    },

    computed: {
        isSelected() {
            if (typeof this.nativeValue === 'object') {
                return this.newValue.map(item => item.id).includes(this.nativeValue.id);
            }

            return this.newValue.includes(this.nativeValue);
        },
    },
};
</script>

<style scoped>
.form-checkbox-button {
    @apply flex flex-shrink-0 items-center py-2.5 px-4 bg-white font-semibold text-gray-700
           leading-none border border-transparent rounded-lg shadow-sm cursor-pointer
           focus:outline-none focus:ring-1 focus:ring-gray-200;
}

.form-checkbox-button.selected {
    @apply bg-brand-500 bg-opacity-10 text-brand-700 border-brand-500 focus:ring-brand-300;
}

.form-checkbox-button.disabled {
    @apply opacity-60 cursor-not-allowed hover:bg-white;
}
</style>
